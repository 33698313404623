<template>
  <main>
    <section class="intro" id="intro">
      <div class="bg-overlay">
        <div class="intro-zh-bg"></div>
      </div>
      <div class="intro-con content">
        <div class="left-con">
          <h1 class="headline-zh-l">招募热忱锋锐的灵魂<br>加入我们<br>创造令人铭记的游戏</h1>
          <p>聚集那些雄心勃勃才华横溢的人，将梦想与幻想变为现实<br>创造令人惊叹的游戏体验，并让玩家沉醉其中</p>
        </div>
        <div class="right-con">
          <router-link class="btn-black"
                       @click.native="updateNavigation(2, true)"
                       to="/zh/#careers">
            <span class="btn-txt">职位列表</span><i class="arrow-right"></i>
          </router-link>
        </div>

      </div>
    </section>

    <section id="about">
    <section class="about content" >
      <h1 class="headline-zh-s">我们是谁 / 奉行理念</h1>
      <div class="paragraph zh">
        <p class="p">Recreate games 是 Source Technology 旗下游戏工作室，目标是创造下一代沉浸体验的娱乐产品。公司由 Matrix Partners、Bertelsmann、Zhen Fund 等基金投资。</p>
        <p class="p">我们认为游戏是种接近造物主的创造形式 – 创造一个世界，让玩家忘却现实而沉浸其中。游戏作为站在艺术和工程科学交叉路口的学科，成功的产品需要编剧、策划、美术、工程完美配合。我们相信精心努力的雕琢产品，是产品成功的关键。</p>
      </div>
      <div class="paragraph zh">
        <p class="p">Recreate 奉行小型团队作业，并相信小团队能产生巨大的能量。我们相信游戏成功因素不来自团队规模，而是因为精锐与充满热情的创作者以及前瞻的创意。</p>
        <p class="p">Recreate 重视公司员工自学与自我驱动能力，并给予所有可能支持。相信这样才是低沟通障碍，高效创作小型团队的基石。我们厌恶任何大公司会出现的疾病，不希望任何人成为某个环节的螺丝钉，我们希望打破职能边界，让员工与团队共同成长，保持探索欲与思路锐利是我们认为最重要的品质。</p>
      </div>
<!--      <p class="btn-wrap"><a class="btn-white"><span class="btn-txt">了解我们更多</span><i class="arrow-right"></i></a></p>-->
    </section>

    <section class="work-env">
      <section class="content">
        <div class="work-env-txt">
          <h1 class="headline-zh-s">工作环境</h1>
          <p class="p">Recreate 总部位于上海，我们为每位工作人员配备了 Haworth ZODY 人体工程学椅，升降桌，空气净化器，大楼整体新风系统，饮料，加班通勤补助。</p>
          <p class="p">除了硬件配置，在职员工还可以免费购买书籍，观看 Pluralsight 与 ABOUTCG 教程以及参加 GDC、Game Jam、Siggraph 等活动。我们非常重视自我学习和行业交流，并认为这是团队成长的关键。</p>
        </div>
        <ul class="work-env-img">
          <li class="work-env-item"><img alt="" src="../assets/img/work-env-1.jpg" @load="imgLoaded"  /></li>
          <li class="work-env-item"><img alt="" src="../assets/img/work-env-2.png" @load="imgLoaded" /></li>
          <li class="work-env-item"><img alt="" src="../assets/img/work-env-3.jpg" @load="imgLoaded" /></li>
          <li class="work-env-item"><img alt="" src="../assets/img/work-env-4.jpg" @load="imgLoaded" /></li>
        </ul>
      </section>
    </section>
    </section>

    <section class="careers" id="careers">
      <section class="content">
        <h1 class="headline-zh-s">职位列表</h1>
        <p class="careers-subtitle">游戏创作需要各行业的人才通力配合。为了打造顶级游戏体验，Recreate 需要招募各行业的顶级人才。我们相信将优秀的创作者聚集在一起，将产生出燎原的火焰</p>
        <ul class="job-list-white">

          <li class="job-item"  v-for="(career, index) in careers" :key="`career-zh-${index}`">
            <span class="job-item-btn"><a class="btn-white">职位详情</a></span>
            <div class="job-item-info">
              <span class="job-name">{{ career.position }}</span>
              <span>{{ career.place }}</span>
              <span>{{ career.time }}</span>
            </div>

            <router-link class="job-link"  :to="`/zh/career/${career.id}`"></router-link>
          </li>
        </ul>
      </section>
    </section>

    <section class="contact" id="contact">

      <div class="content contact-con">
        <div class="left-con">
          <h1 class="headline-zh-l">Let’s Do Game<br>加入我们一展抱负<br>立刻联系</h1>
        </div>
        <div class="right-con">
          <a class="btn-black" href="mailto:Careers@recreate.games"><span class="btn-txt">发送邮件</span></a>
        </div>
      </div>

      <ul class="content contact-list">
        <li class="contact-item">
          <h2 class="headline-zh-l">Player Support</h2>
          <p class="contact-title"><a href="mailto:Support@recreate.games">Support@recreate.games</a></p>
        </li>
        <li class="contact-item">
          <h2 class="headline-zh-l">Business</h2>
          <p class="contact-title"><a href="mailto:Business@recreate.games">Business@recreate.games</a></p>
        </li>
        <li class="contact-item">
          <h2 class="headline-zh-l">Employment</h2>
          <p class="contact-title"><a href="mailto:Careers@recreate.games">Careers@recreate.games</a></p>
        </li>
        <li class="contact-item">
          <h2 class="headline-zh-l">Media Report</h2>
          <p class="contact-title"><a href="mailto:Media@recreate.games">Media@recreate.games</a></p>
        </li>
      </ul>
    </section>
  </main>
</template>

<script>

import {scrollTop} from '@/utils/utils'

export default {
  name: "HomeZh",
  data(){
    return {
      activatedNav: 0,
      scrollTops: [],
      halfWindowHeight: 0,
      imgLoadedNum: 0,
      imgNeedPreload: 4, // constant
      scrollDebounceBinder: null,
      scrollThrottleBinder: null,
      getOffsetTopBinder: null,
    }
  },
  props: {
    careers: Array,
    navClicked : Boolean,
    currentNav: Number,
  },
  beforeDestroy(){
    document.removeEventListener('scroll', this.scrollDebounceBinder, false);
    document.removeEventListener('scroll', this.scrollThrottleBinder, false);
    window.removeEventListener('resize', this.getOffsetTopBinder, false);
  },
  mounted() {
    this.scrollDebounceBinder = this.scrollDebounce(this.scrollHandle, 300);
    this.scrollThrottleBinder = this.scrollThrottle(this.activeNavItem, 300).bind(this);
    this.getOffsetTopBinder = this.getOffsetTop.bind(this);

    document.addEventListener('scroll', this.scrollDebounceBinder, false );
    document.addEventListener('scroll', this.scrollThrottleBinder, false );
    window.addEventListener('resize', this.getOffsetTopBinder, false);
  },
  methods: {
    getOffsetTop() {
      let introTop = 0;
      let aboutTop = document.querySelector('#intro').clientHeight;
      let careerTop = aboutTop + document.querySelector('#about').clientHeight;
      let contactTop = careerTop + document.querySelector('#careers').clientHeight;
      this.scrollTops = [introTop, aboutTop, careerTop, contactTop];
      this.halfWindowHeight = window.innerHeight / 2;
      this.$emit('update-scrolltops', this.scrollTops);
    },
    imgLoaded(){
      this.imgLoadedNum ++;
      if(this.imgLoadedNum === this.imgNeedPreload) {
        this.getOffsetTop();
        this.fixScrollTop();
      }
    },
    /*
    * 滚动防抖
    * */
    scrollDebounce(fn, wait ){
      let timeout = null;

      return function () {
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout( fn, wait);
      }
    },
    scrollHandle() {
      if(this.navClicked){
        this.$emit( 'reset-nav-clicked-trigger');
      }
    },
    /*
    * 滚动节流
    * */
    scrollThrottle(fn, wait) {
      let startTime = Date.now();
      let timeout = null;

      return function () {
        if(this.navClicked){
          return false;
        }
        let now = Date.now();
        clearTimeout(timeout);
        if(now - startTime >= wait) {
          startTime = now;
          fn();
        }else {
          timeout = setTimeout(fn, wait)
        }
      }
    },
    fixScrollTop(){
      this.$emit('img-loaded', this.scrollTops);
    },
    activeNavItem() {
      let offsetTop = scrollTop() + this.halfWindowHeight;
      for( let i = 0; i < this.scrollTops.length; i++) {
        if( ( i < this.scrollTops.length - 1 && offsetTop > this.scrollTops[i] && offsetTop <= this.scrollTops[i+1] )
            || ( i === this.scrollTops.length - 1 &&  offsetTop > this.scrollTops[i] ) ){
          this.activatedNav = i;
          break
        }
      }
      this.updateNavigation( this.activatedNav);
    },
    updateNavigation(activatedNav, isClicked){
      if(activatedNav === this.currentNav && !isClicked)
        return
      this.$emit('update-nav', activatedNav, isClicked);
    },
  }
}
</script>

<style scoped>

</style>