<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      preferredLang: this.$route.params.lang, // {en, zh}
    }
  },
}
</script>


<style>
 @import "assets/css/common.css";
 /* @import '~@fortawesome/fontawesome-free/css/all.css';*/
</style>
