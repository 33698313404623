<template>
  <div>
    <section class="nav-wrapper"
             :class="{'fixed': isNavFixed}">
      <nav :class="{'hide-nav': foldNav}">
        <h1 class="logo-wrap"
            @click="updateNav(0, true)">
          <router-link id="logo" to="/zh/#intro"><img alt="Recreate" src="../assets/img/Recreate.svg"></router-link>
        </h1>
<!--        <p class="nav-right"><a class="search icon-salient-search"></a></p>-->
        <div class="normal-nav">
          <p class="nav-right"><router-link to="/en">English</router-link></p>
          <ul class="nav-items">
            <li class="nav-item"
                @click="updateNav(0, true)"
                :class="{'active': activatedNavItem === 0}">
              <router-link to="/zh/#intro">首页</router-link>
            </li>
            <li class="nav-item"
                @click="updateNav(1, true)"
                :class="{'active': activatedNavItem === 1}">
              <router-link to="/zh/#about">关于我们</router-link>
            </li>
            <li class="nav-item"
                @click="updateNav(2, true)"
                :class="{'active': activatedNavItem === 2}">
              <router-link to="/zh/#careers">招聘职位</router-link>
            </li>
            <li class="nav-item"
                @click="updateNav(3, true)"
                :class="{'active': activatedNavItem === 3}">
              <router-link to="/zh/#contact">联系我们</router-link>
            </li>
          </ul>
        </div>

        <div class="nav-btn"
             @click="toggleMobileNav($event)">
          <a class="btn-lines"></a>
        </div>
      </nav>
      <div class="mobile-nav"
           :class="{'show': isShowMobileNav && foldNav}" >
        <ul class="mobile-nav-list">
          <li class="mobile-nav-item"
              @click="updateNav(0, true)">
            <router-link to="/zh/#intro">首页</router-link>
          </li>
          <li class="mobile-nav-item"
              @click="updateNav(1, true)">
            <router-link to="/zh/#about">关于我们</router-link>
          </li>
          <li class="mobile-nav-item"
              @click="updateNav(2, true)">
            <router-link to="/zh/#careers">招聘职位</router-link>
          </li>
          <li class="mobile-nav-item"
              @click="updateNav(3, true)">
            <router-link to="/zh/#contact">联系我们</router-link>
          </li>
          <li class="mobile-nav-lang"><router-link to="/en">English</router-link></li>
        </ul>
      </div>
    </section>



    <router-view
        :careers = careers
        :navClicked = "isClickedTrigger"
        :currentNav = "activatedNavItem"
        v-on:img-loaded = "imgLoaded"
        v-on:update-scrolltops = "updateScrollTops"
        v-on:update-nav = "updateNav"
        v-on:reset-nav-clicked-trigger = "resetClickedTrigger"
    >
    </router-view>

    <footer>
      <p class="footer-con content">© {{new Date().getFullYear()}} Recreate Games. Source Technology Inc. 版权所有</p>
    </footer>
  </div>
</template>

<script>
import { scrollTop } from '@/utils/utils';
export default {
  name: "AppZh",
  data(){
    return {
      isShowMobileNav: false,
      foldNav: false,
      isNavFixed: false,
      activatedNavItem: 0,
      isClickedTrigger: false,
      navs: ['#intro', '#about', '#careers', '#contact'],
      scrollingBinder: null,
      careers: [
        {
          id: 0,
          position: '游戏执行策划',
          place: '上海',
          time: '全职',
          descri: '不同平台的游戏版本体验需要保持一致，但是根据每个平台的特性和玩家习惯进行合适的修改也非常重要。你将会负责将游戏从 PC 端向主机端和移动端的需求修改，以及项目管理，最大程度上让游戏在其他平台上也获得原生平台的质感。',
          responsibility: '负责在移植游戏过程中提出针对平台特性的修改需求\n管理游戏开发进度',
          required: '热爱游戏\n有负责游戏跨平台的经验，能够独立推动项目进展\n对PC、主机、移动端游戏有深刻理解（一个或以上）\n能够根据不同平台玩家特性对游戏需求进行优化和修改\n具备良好的沟通协调能力和组织能力，执行力强，有责任心，有较强的抗压能力',
          plus: '参与过一款游戏的完整开发流程\n有游戏开发管理经验',
          materials: '个人简历'
        },
        {
          id: 1,
          position: '概念设计总监',
          place: '上海',
          time: '全职',
          descri: '游戏美术风格的明确性和统一性至关重要。你将会负责游戏概念设计团队的整体风格制定；概念设计团队产出的规范；以及对整体美术风格的把控，确保所有资产的风格统一。',
          responsibility: '负责把握游戏整体视觉风格和概念设计\n整体负责并领导概念设计团队的工作，确保产出效率\n绘制气氛图，设计图并整合概念美术资源\n和制作人以及其他团队总监一同确保游戏最终质量\n为概念设计团队成员提供技术和职业规划上的指导\n和其他艺术总监一同持续定义并持续游戏独有的视觉风格',
          required: '美术基础扎实，对色彩，光影和材质感觉敏锐，想象力丰富，具有较高的审美能力\n对美术有独立的理解和体会，并愿意持续探索美术新风格\n具备良好的沟通协调能力和组织能力，执行力强，有责任心，有较强的抗压能力\n参与过至少一款游戏的开发完整流程\n英语沟通能力强（书面、口语、听力）',
          plus: '留学背景',
          materials: '个人简历\n个人作品集'
        },
        {
          id: 2,
          position: '角色概念设计师',
          place: '上海',
          time: '全职',
          descri: '好的角色的设计都对玩家代入感的生成有着至关重要的作用。你需要不断设计出符合游戏要求的人物形象与服饰、配饰。',
          responsibility: '制定游戏角色风格，输出角色原画设计\n执行概念设计总监的概念设计精神\n与 3D 美术共同创造游戏中的角色资产\n根据项目需求，完成高品质的 3D 角色\n关注行业动态，保证美术品质领先',
          required: '熟悉生物解剖结构\n美术基础扎实，对色彩和造型感觉敏锐，具有较高的审美能力\n具备协作精神，责任心强，擅长快速学习使用美术相关软件\n拥有卡通风格化角色设计能力',
          plus: '参与过一款游戏的完整开发流程\n熟练使用至少一个 3D 软件',
          materials: '个人简历\n个人作品集'
        },
        {
          id: 6,
          position: '场景概念设计师',
          place: '上海',
          time: '全职',
          descri: '高质量的场景对于提升体验来说至关重要，我们需要用有限的性能表现出最佳的画面。你需要设计出符合游戏设定的场景，寻找合适的制作途径，最大化地体现游戏场景的可玩性和互动性。',
          responsibility: '和概念设计总监一起定义游戏视觉风格和效果，产出明确、详细、实际可行的概念设计\n负责项目场景设计，输出符合游戏风格的场景\n独立绘制场景概念图，场景物件设计等',
          required: '有较好的想象力和场景氛围表达能力\n对场景色彩和光影效果及空间感有良好的把控能力\n具有将游戏玩法需求转换成视觉设计的能力：收集参考、调研、绘制剪影、细化、产出\n美术基础扎实，对色彩和造型感觉敏锐\n具备协作精神，责任心强，擅长快速学习使用美术相关软件',
          plus: '参与过一款游戏的完整开发流程\n熟练使用至少一个 3D 软件辅助设计',
          materials: '个人简历\n个人作品集'
        },
/*        {
          id: 3,
          position: '外包管理经理',
          place: '上海',
          time: '全职',
          descri: '合理的使用优秀的外包资源对游戏开发进度有着非常积极的影响。你的任务将是联系这些优秀的外包公司和个人，并在达成合作后对他们的产出质量负责。',
          responsibility: '负责协调与外包团队的合作，推动项目进展\n负责与外包团队合作中的风险管理，解决项目实施过程中的实际问题',
          required: '有游戏或互联网相关的项目管理经验\n能够理解游戏的艺术风格，并与外包团队进行沟通\n了解游戏美术的工作流程，给予外包团队有效反馈\n具备良好的沟通协调能力和组织能力，执行力强，有责任心，有较强的抗压能力\n英语沟通能力强（书面、口语、听力）',
          plus: '参与过一款游戏或影视作品的外包管理工作\n留学经历',
          materials: '人简历'
        },*/
        {
          id: 4,
          position: '3D 资产制作总监',
          place: '上海',
          time: '全职',
          descri: '在优秀的概念设计出炉之后，3D 资产的执行至关重要。在 3D 资产制作团队中，美术规范的制定也将决定团队的产出效率。你将负责忠实地执行概念设计团队的设计意图，确保团队执行合理的美术规范，并且统一 3D 资产风格。',
          responsibility: '与概念设计总监合作，领导 3D 团队制作出符合概念设计的 3D 资产\n负责 3D 美术团队的管理工作，制定并把控美术规范\n负责游戏最终视觉效果\n确保游戏中角色与场景风格统一\n关注行业动态，保证美术品质领先\n关注行业动态，保证美术品质领先\n为3D团队成员提供技术和职业规划上的指导',
          required: '熟悉 3D 美术工作流程，能够推动美术，技术美术等团队实现目标效果\n熟悉 UNITY 或 UNREAL ENGINE，并熟练掌握常用美术软件工具\n熟悉 3D 资产制作的全部流程（建模、绑定、渲染等）\n对美术有独立的理解和体会，并愿意持续探索美术新风格\n英语沟通能力强（书面、口语、听力）\n参与过至少一款游戏的完整开发流程',
          plus: '留学背景',
          materials: '个人简历\n个人作品集'
        },
        {
          id: 7,
          position: '资深 3D 场景美术',
          place: '上海',
          time: '全职',
          descri: '在游戏里，高质量的场景对于提升体验来说至关重要，我们需要用有限的性能表现出最佳的画面。你需要理解设计师的想法，寻找合适的制作途径，最大化地还原出概念场景。',
          responsibility: '将概念设计制作成 Unity 中的可用资产\n产出可直接供游戏使用的实时 3D 场景资产',
          required: '具有过硬的审美能力\n对灯光、材质有深刻的理解\n精通 3D 类设计软件\n能独立完成场景模型制作、UV 拆分、材质绘制、贴图烘焙\n具有 UNITY 或 UNREAL ENGINE 使用经验\n具有较强的故障排除能力和学习能力\n可以自己补全概念设计并产出符合风格的资产',
          plus: '具有离线渲染器使用经验\n有参与次时代游戏制作的经历\n具有 SHADER 制作经验\n具备建筑类学科背景',
          materials: '个人简历\n个人作品集'
        },
        {
          id: 5,
          position: '美术资产管理',
          place: '上海',
          time: '全职',
          descri: '美术资产生产流程的管理至关重要。你需要和各美术部门总监一同制定并优化美术资产的生产流程，创造工具和规范，解决美术团队遇到的生产力问题，确保美术资产的按时产出。',
          responsibility: '和各美术部门总监一同持续优化游戏美术资产的生产流程\n和概念设计总监、各美术部门总监和美术团队一起制定重要的时间节点，制定目标和要求\n帮助安排概念设计总监的时间表和优先级\n协调美术团队的沟通和同步所有人的工作进度\n维持美术团队的任务时间表，确保美术资产按计划产出\n帮助美术团队解决由美术工具和pipelines带来的生产力问题',
          required: '掌握基本 3D 软件\n熟悉 3D 美术工作流程，能够推动美术，技术美术等团队实现目标效果\n具备协作精神，责任心强，擅长快速学习使用美术相关软件',
          plus: '参与过一款游戏或影视作品的完整开发流程',
          materials: '个人简历'
        },
        {
          id: 8,
          position: '3D 动画设计师',
          place: '上海',
          time: '全职',
          descri: '游戏需要将画面从时间线上变得令人信服，这其中的关键便是动画。无论是角色、物体、动力学或粒子效果，优秀的动画甚至能将一个茶壶变得栩栩如生。我们希望你能够了解各种经典的动画表述方式，以及使用先进的技术将其表现出来。这个职位不止需要你在学校或之前你工作中所使用过的动画技法，我们还希望使用更多先进的技术改进动画效果或制作效率。',
          responsibility: '创造早期动画 2D 或 3D 分镜草图\n为 2D 或 3D 角色创造角色动画或物体动画\n对 3D 物品实施绑定，并与工程团队协调方案可行性\n参与动作捕捉流程',
          required: '热爱游戏\n熟悉线性和非线性动画创作流程\n熟悉人物角色和各种动物骨骼绑定\n了解如何处理体积塌陷，膨胀等动画常见技术问题\n了解行为树和引擎动作混合机制\n有极强的自驱和自学能力',
          plus: '参与过一款 AAA 级游戏完整从早期到上线流程\n熟悉 Unity 或 Unreal 引擎\n熟悉光学或惯性动捕流程\n熟悉任何 3D 工具的粒子或动力学系统',
          materials: '个人简历\n个人作品集'
        },
        {
          id: 18,
          position: '技术美术',
          place: '上海',
          time: '全职',
          descri: '',
          responsibility: '参与游戏材质、后处理以及特效的制作，优化渲染效果及效率，开发美术工具等',
          required: '有成熟的移动平台开发及优化经验\n熟练掌握渲染管线、常用光照模型、毛发渲染、粒子效果、后处理等\n熟悉 Unity URP/HDRP \n有良好的 Shader 编写能力，能够制作各类渲染风格的材质\n2年以上 Unity/Unreal 使用经验\n有良好的审美\n优秀的沟通能力、自学能力\n热爱游戏',
          plus: '熟悉 Houdini \n有程序化生成的相关制作经验\n可以根据需求开发美术所需的相关工具脚本\n完整参与过任意平台的游戏类产品并上线\n有良好编程基础',
          materials: '个人简历\n个人作品（不限于视频、可执行程序）'
        },
        {
          id: 26,
          position: '3D 渲染美术',
          place: '上海',
          time: '全职',
          descri: '',
          responsibility: '参与游戏角色和场景的效果图渲染工作\n负责从灯光、材质、动作、渲染到后期修图的整体流程',
          required: '精通 3D 类软件\n精通至少一款渲染器\n能独立完成从前期 3D 渲染到后期平面修图的完整工作\n有优秀的审美\n有良好的沟通协调能力，执行力强，有责任心',
          plus: '热爱游戏，有大量游戏经验\n有 Unity 或 Unreal Engine 的使用经验\n有从事影视制作的经验\n有扎实的美术基础或绘画功底',
          materials: '个人简历\n个人作品集'
        },
        {
          id: 15,
          position: '游戏音频设计师',
          place: '上海',
          time: '全职',
          descri: '在游戏世界里，优秀的游戏音频设计对于提升玩家的游戏体验来说至关重要。游戏音频设计师需要根据我们的游戏风格，设计出高质量的音频。',
          responsibility: '按照设计方案独立完成游戏音效制作\n通过录音设备、音频工作站（DAW）、效果器插件等软硬件设备，进行项目所需的录音创作编辑后期混音等\n负责在项目版本迭代中持续维护、调优，保证项目音频开发进度，解决游戏研发过程中产生的音频问题',
          required: '熟练掌握 FMOD 的使用\n至少做过一款上线游戏\n三年以上游戏音频或影视、动漫音频专业经验，熟知游戏音频各细分工种职能、游戏音频体系各子系统内容，在其中一项或多项有所专长\n对行业及产品有清晰了解，熟悉游戏产品及声音开发流程\n热爱游戏行业，对国内外主流游戏有相当的熟悉和理解\n优秀的音乐审美和赏析能力\n良好的沟通能力',
          plus: '作曲或录音专业相关教育背景\n有物理引擎动作游戏项目经验',
          materials: '个人简历\n个人作品集'
        },
        {
          id: 24,
          position: 'UI 设计师',
          place: '上海',
          time: '全职',
          descri: '',
          responsibility: '参与游戏 UI 的设计和制作，包括整体界面、图标、动效等\n配合工程师完成游戏界面的实现，保证最终效果与设计品质一致',
          required: '有完整、独立的界面设计和执行经验\n精通设计类软件\n有优秀的审美\n有求知欲望和优秀的自学能力\n有良好的沟通协调能力，执行力强，有责任心',
          plus: '热爱游戏，有大量游戏经验\n熟悉 3D 类软件\n有 Unity 或 Unreal Engine 的使用经验\n精通动画制作（2D、3D 皆可）\n有扎实的美术基础或手绘功底',
          materials: '个人简历\n个人作品集'
        },
/*        {
        id: 25,
        position: '平面设计师',
        place: '上海',
        time: '全职',
        descri: '',
        responsibility: '负责游戏内外的线上宣发类平面设计\n负责线下的活动、品牌包装等实体平面设计',
        required: '精通设计类软件\n有优秀的审美和美术功底，精通构图、排版、色彩\n对设计有自己的理解，能独立完成整套成体系的视觉框架和设计规范\n有良好的沟通协调能力，执行力强，有责任心',
        plus: '热爱游戏，有大量游戏经验\n熟悉 3D 类软件\n熟悉印刷工艺及规范、有印刷品的设计和执行经验',
        materials: '个人简历\n个人作品集'
      },*/
/*        {
          id: 9,
          position: 'IT 支持工程师',
          place: '上海',
          time: '全职',
          descri: '',
          responsibility: '负责公司IT系统的搭建、硬件网络接入等工作\n负责公司内部路由器、交换机、防火墙设备以及服务器的日常维护\n负责IT系统及硬件设备的管理、维护、变更、优化、故障处理和应急恢复等运维操作及运维管理相关工作\n负责IT系统的安全稳定运行，组织配合做信息系统安全检查',
          required: '一年以上IT相关工作经验，本科及以上学历\n精通路由、交换机、防火墙、网络监控等设备安装调试（包括：CISCO， UBNT 等厂家设备）\n熟悉桌面 PC、Mac 硬件组成、硬件原理、部件识别及故障诊断，能独立进行台式机、笔记本故障排查\n了解桌面外设相关（打印机、投影仪、Apple TV 等）设备配置及常见故障排除\n熟悉 IT 服务管理体系，有较强的问题发现及分析解决能力\n有良好的沟通和交流能力，较强的服务意识',
          plus: '影视游戏公司 IT 运维经验\n熟悉常用版本控制软件服务器和客户端安装维护',
          materials: '个人简历'
        },*/
        
        {
          id: 11,
          position: '游戏动画工程师',
          place: '上海',
          time: '全职',
          descri: '在游戏中动画质量同样重要，游戏动画工程师需要根据需求开发高级动画效果，增强游戏表现力。',
          responsibility: '参与开发程序化角色动画、物理计算性能优化等',
          required: '热爱游戏\n有良好的审美\n良好的数学和物理基础知识，对角色动作和物体运动有深刻的理解\n扎实的计算机学科基础知识\n拥有超过两年的 C# 编程经验和良好的编程习惯\n熟练掌握 Unity 引擎使用\n拥有计算机科学、数学或相关学士以上学位\n优秀的沟通能力、自学能力',
          plus: '有程序化动画制作经验\n有 Havok 引擎使用经验\n有参与开发物理引擎经验\n有 Unity ECS 和 C# Job System 使用经验\n有 3D 动画制作经验\n拥有移动平台性能优化与兼容性调试经验\n完整参与过任意平台的游戏类产品并上线',
          materials: '个人简历\n个人动画作品（不限于视频、代码、可执行程序）'
        },
        {
          id: 12,
          position: '图形图像工程师',
          place: '上海',
          time: '全职',
          descri: '在游戏中性能和图像质量同样重要，图形图像工程师需要在满足性能的前提下将画质推到极限。你将会和工程师、设计师共同推进游戏的图形图像技术，分析目前业务需求，以建立和改进图形图像相关代码库。',
          responsibility: '参与游戏渲染效果制作、优化游戏图形性能等',
          required: '热爱游戏\n有良好的审美\n扎实的计算机学科基础知识\n良好的数学和物理功底\n对渲染管线、常用光照模型、毛发渲染、粒子效果、后处理等有深刻的理解\n有良好的 Shader 编程能力，能根据项目需求独立开发和优化视觉效果\n2 年以上 Unity/Unreal 使用经验\n拥有计算机科学、数学或相关学士以上学位\n优秀的沟通能力、自学能力',
          plus: '熟悉任意一款 3D 软件\n拥有移动平台图像性能优化与兼容性调试经验\n完整参与过任意平台的游戏类产品并上线',
          materials: '个人简历\n个人作品（不限于视频、可执行程序）'
        },
        {
          id: 13,
          position: '资深游戏服务器开发工程师',
          place: '上海',
          time: '全职',
          descri: '资深游戏服务器工程师需要负责游戏服务器架构设计和开发，这需要你有多年的服务器实际项目经验。你将会和技术团队一同解决更深度的服务器线上问题，保证游戏稳定运行。资深游戏服务器工程师是团队可以依靠的技术人员。',
          responsibility: '负责游戏战斗及网关服务器的架构设计，功能开发维护\n快速定位解决服务器线上问题，保证服务稳定运行\n分析服务器瓶颈，结合游戏性能及稳定性进行调优',
          required: '大学本科及以上学历\n3年以上服务器开发经验，有上线游戏研发维护经验\n具有服务器性能瓶颈分析能力和优化经验\n熟练掌握 C++、Java、C# 及 Python 中的一种或者多种，有良好的编程习惯，配合团队编程规范的素养\n基础扎实，熟练掌握算法，数据结构相关知识，熟悉面对对象编程，具备合理运用设计模式的能力\n熟悉 Linux，有扎实的 Linux 编程环境经验，熟悉 TCP/IP 协议、UDP 协议、HTTP 协议\n熟练使用 NoSQL, 熟悉 NoSQL 数据设计模型\n能够熟练阅读英文技术文档\n较强的学习能力和责任心',
          plus: 'Unity3d 客户端开发经验，性能优化经验\nNoSQL 性能调优经验，数据迁移经验\nAWS 开发运维经验',
          materials: '个人简历'
        },
        {
          id: 14,
          position: '游戏服务器开发工程师',
          place: '上海',
          time: '全职',
          descri: '游戏服务器工程师需要负责游戏服务器架构业务逻辑的开发和运维。你将会和技术团队一同解决服务器线上问题，保证服务稳定运行。',
          responsibility: '负责游戏战斗及网关服务器的架构业务逻辑开发维护\n快速定位解决服务器线上问题，保证服务稳定运行\n分析服务器瓶颈，结合游戏性能及稳定性进行调优',
          required: '大学本科及以上学历\n1年以上游戏服务器开发经验\n熟练掌握 C++、Java、C# 及 Python 中的一种或者多种，有良好的编程习惯配合团队编程规范的素养\n基础扎实，熟练掌握算法，数据结构相关知识，熟悉面对对象编程和常用设计模式\n熟悉 Linux 使用和开发环境，熟悉 TCP/IP 协议、UDP 协议、HTTP 协议\n熟练使用 NoSQL, 熟悉 NoSQL 数据设计模型\n能够熟练阅读英文技术文档\n较强的学习能力和责任心',
          plus: '上线游戏开发经验\nUnity3d 客户端开发经验\nAWS 开发运维经验',
          materials: '个人简历'
        },
        {
          id: 19,
          position: '游戏客户端工程师',
          place: '上海',
          time: '全职',
          descri: '工程实现是游戏成功的基石。开发工程师将会参与游戏开发的各种领域，如游戏逻辑、图形图像、用户界面、用人工智能改进游戏。开发工程师需要在各种硬件受限的情况下解决前人从未解决的难题，创造出健硕的工程并交付给百万人使用。',
          responsibility: '',
          required: '热爱游戏\n扎实的计算机学科基础知识，包括算法、数据结构和设计模式\n拥有超过两年的 C# 编程经验和良好的编程习惯\n熟练掌握 Unity 引擎使用\n广泛了解游戏开发的不同领域，如图形图像、玩法、测试方法等等\n拥有计算机科学、数学或相关学士以上学位\n优秀的沟通能力',
          plus: '完整参与过任意平台的游戏类产品并上线\n拥有 3D 软件的模型、材质、动画等模块完整使用经验\n拥有移动平台性能优化与兼容性调试经验\n超过 2 年的 C++ 实际项目经验\n为开源项目社区如 Github 或 Bitbucket 贡献过代码',
          materials: '个人简历\n个人项目代码样本'
        },
        {
            id: 32,
            position: 'Xbox 游戏移植开发工程师',
            place: '上海',
            time: '全职',
            descri: '工程实现是游戏成功的基石。XBOX 游戏移植开发工程师将会参与游戏的各个模块从 PC 平台到 XBOX 平台的移植工作，如游戏逻辑、图形图像、用户界面等。XBOX 游戏移植开发工程师需要在 XBOX 主机的软硬件限制下解决一系列兼容性问题，创造出健硕的工程并交付给百万人使用。',
            responsibility: '负责游戏在 XBOX 平台的开发及移植工作\n负责游戏在 XBOX 平台上的性能监控及优化\n负责 XBOX 游戏业务逻辑开发\n协助美术/策划建立高效的游戏移植流程，完善并持续优化工具链',
            required: '热爱游戏\n扎实的计算机学科基础知识，包括算法、数据结构和设计模式\n拥有超过两年的 C# 编程经验和良好的编程习惯熟练掌握 Unity 引擎使用\n广泛了解游戏开发的不同领域，如图形图像、玩法、测试方法等等\n拥有计算机科学、数学或相关学士以上学位\n一年以上的主机游戏移植工作经历\n优秀的沟通能力',
            plus: '完整参与过其他平台的游戏类产品主机移植工作并上线\n拥有移动平台性能优化与兼容性调试经验\n超过 2 年的 C++ 实际项目经验\n为开源项目社区如 Github 或 Bitbucket 贡献过代码',
            materials: '个人简历\n个人项目代码样本',
            
          
        },
/*        {
          id: 20,
          position: '高级财务经理',
          place: '上海',
          time: '全职',
          descri: '',
          responsibility: '负责公司的财务预算及财务分析工作，定期编制管理报表及财务分析报告，为管理层决策提供及时有效的数据支撑及风险预警\n负责公司境内外业务的税收筹划，制定符合政策的税务规划及建议，避免涉税风险\n负责公司财务各职能工作，包括不限于合并报表编制、会计核算、税务、资金等\n根据公司业务发展情况，建立完善的会计核算体系、财务内控管理体系、全面预算管理体系、资金管理体系、成本管理体系等，并跟进实施和反馈，提升财务管控效率',
          required: '具有全面扎实的财务专业知识、账务处理及财务管理经验\n精通国家会计准则及相关的财务、税务、审计等政策\n有大型跨国企业税务筹划经验\n6 年以上企业财务工作经验，3 年以上企业财务管理经验\n为人正直，有优秀的沟通、协调能力及敏锐的分析判断力，抗压能力强，有高度的责任心\n精通办公软件，熟练使用专业数据分析工具\n持有 CPA、CFA 证书\n本科及以上学历，财务金融类专业',
          plus: '四大事务所 manager 经验\n英语流利',
          materials: '个人简历'
        },*/
        {
          id: 21,
          position: '视频编解码工程师',
          place: '武汉/北京',
          time: '全职',
          descri: '这个职位需要创造一种跨平台解决方案，使视频质量更好的同时提供更小的尺寸。VR/AR 对于图像质量极高，目前我们可以回放双 4K 视频在 60 帧率，不远的未来还有 8K、12K、16K 视频。不只是帧率和分辨率的提升，还有色彩深度的加强。这些超高分辨率的视频将非常难以传输、存储和解压回放。为了实现完全沉浸式的体验，我们工程团队将面临各个方面严峻的技术挑战。',
          responsibility: '',
          required: '在图像编解码方向上有充足的经验，如 H.264/H.265/VP9/AV1\n有不同平台的编程经验，如 Windows, Linux, Android, iOS\n拥有 C/C++ 扎实的编程能力\n拥有 3 年以上相关行业从业经验\n优秀的分析，设计和 Debug 能力\n拥有计算机科学、数学或相关硕士以上学位\n参与过完整的任意平台应用软件开发',
          plus: '计算机或数学相关博士学位\n拥有一款 VR/AR 设备并使用超过一年\n为开源项目社区如 Github 或 Bitbucket 贡献过代码',
          materials: '个人简历\n个人项目代码样本'
        },
/*        {
          id: 22,
          position: '网络工程师',
          place: '上海',
          time: '全职',
          descri: '',
          responsibility: '负责公司内网系统的选型、搭建、安装、运维等工作，包括但不限于版本控制系统，代码审查系统，持续集成系统，公司内网，VPN 系统等\n负责公司内部路由器、交换机、防火墙设备以及服务器的运行维护\n负责公司系统、网络等疑难故障的排查和修复',
          required: '本科及以上学历，3 年以上网络技术、系统运维相关工作经验\n熟悉 TCP/IP 协议，常见路由协议，VPN、NAT 原理，有较强的分析故障排查能力\n精通 CISCO 路由、交换机设备安装调试运维；熟悉内网权限理管理，子网划分\n有网络运维管理经验\n熟悉 Linux 操作系统，熟练使用命令行工具，至少熟练使用一种脚本语言\n熟悉常见版本控制系统、持续集成系统、VPN系统的安装维护，权限管理，日常运维\n熟悉桌面 PC、Mac 硬件组成、硬件原理、部件识别及故障诊断，能独立进行台式机、笔记本故障排查\n了解桌面外设相关（打印机、投影仪、Apple TV 等）设备配置及常见故障排除',
          plus: '影视游戏公司内网运维经验',
          materials: '个人简历'
        },*/
        {
          id: 27,
          position: '网络安全工程师',
          place: '上海',
          time: '全职',
          descri: '',
          responsibility: '定期进行安全风险评估、渗透测试、漏洞扫描，制定安全规程\n应急响应、调查取证安全事件，快速定位、解决安全问题',
          required: '全日制本科及以上学历，3 年以上互联网企业的信息安全从业经验\n扎实的计算机基础知识，精通常见 web、系统安全漏洞原理、利用方式和解决方案\n熟练使用常用安全工具，熟悉内网渗透技术和安全解决方案\n熟悉 Windows 或 Linux 操作系统，至少熟悉一门编程语言，能够开发自动化的分析工具\n良好的沟通、协作能力',
          plus: '拥有 CISSP、CISA、CISP、CIPT 证书\n有编写漏洞利用POC能力与Web漏洞扫描器开发经验',
          materials: '个人简历'
        },
        {
          id: 31,
          position: '游戏测试经理',
          place: '上海',
          time: '全职',
          descri: '该职位主要负责游戏 Party Animals 测试工作。',
          responsibility: '理解和分析游戏模式，制定测试计划，共同确定并实现产品质量目标\n设计测试方法，编写测试用例，构建和管理测试用例库\n执行测试，分析和定位测试中遇到的问题，协助解决问题\n形成可读性强的测试报告，对游戏软件质量进行评估\n统计和分析用户反馈的问题，协助开发人员解决用户问题',
          required: '熟悉 Steam 游戏平台或 XBOX, PlayStation 等主机平台\n扎实的计算机基础知识，熟悉游戏开发流程\n完整参与过任意平台游戏产品并上线\n有着丰富的多平台游戏软件性能测试和压力测试经验\n对游戏和软件质量充满热情\n拥有计算机科学、软件或相关学士以上学位',
          plus: '有外包测试管理经验\n有任意平台游戏编程经验\n熟悉 Unity 引擎',
          materials: '个人简历'
        },
        {
          id: 23,
          position: '产品测试工程师',
          place: '武汉',
          time: '全职',
          descri: '该职位将会负责 VR/AR 应用软件上线测试，保证应用的每个版本在不同的平台保持优异的性能和稳定性。',
          responsibility: '与产品⼈员沟通完善产品需求，参与需求分析和评审，协助制定测试方案和测试计划\n参与设计、执行测试用例，撰写测试报告\n负责产品发布前的实验室应用测试，包括部署测试及功能测试\n复现问题并验证功能，提出改进方案\n对异常测试结果及时记录和分析，及时反馈于研发',
          required: '2 年及以上测试/开发工作经验\n了解系统的基本原理和测试方法，善于从使用者角度考虑问题，拥有用户思维\n具备良好的问题分析能力、团队协作能力、沟通能力和中英文文档撰写能力\n拥有计算机、自动化或相关学士以上学位',
          plus: '掌握一种编程语言，如 C/C++、Java、Python 等\n白盒测试开展经验',
          materials: '个人简历'
        },


        {
          id: 28,
          position: 'Web 前端工程师（互动直播）',
          place: '上海',
          time: '全职',
          descri: '',
          responsibility: '',
          required: '本科及以上学历，2 年以上前端工作经验\n扎实的前端基础（HTML5/CSS3/JavaScript），理解 web 标准\n精通 React 技术栈\n了解 Node.js 或其他后端开发语言及框架\n可独立完成中型前端项目的设计与开发，能够充分沟通理解需求并落地\n具有较强的沟通能力，分析和解决问题的能力，具备良好的团队合作精神',
          plus: '熟悉一种及以上后端开发语言（Go/Java/Python）\n熟悉 WebRTC 等实时通讯技术\n熟悉主流网络流媒体协议，如 RTMP、RTSP、HLS等\n有优秀的技术 sense 和一定的产品 sense，善于创新拓展',
          materials: '个人简历'
        },
        {
          id: 29,
          position: 'Web 后端工程师（互动直播）',
          place: '上海',
          time: '全职',
          descri: '',
          responsibility: '',
          required: '本科及以上学历，2 年以上后端工作经验，负责互动直播系统的后端搭建\n熟练掌握两种以上后端语言（Go/C++/Java）\n熟悉一种以上数据库基本操作（SQL/MongoDB/Redis），理解其容灾原理，具备定位其性能问题的能力\n具备使用 Aliyun、AWS 或 GCP 等国内、外主流公有云的经验\n重视代码质量及研发规范\n具有较强的沟通能力，分析和解决问题的能力，具备良好的团队合作精神',
          plus: '有 ACM-ICPC 等程序设计竞赛参赛、获奖经历\n熟悉脚本语言，有搭建使用复杂系统的能力\n熟悉视频编解码以及相关技术\n熟悉 WebRTC 等实时通讯技术\n熟悉云计算/容器/云原生技术（OpenStack，LXD，runC，Kubernetes，cloud native stack）',
          materials: '个人简历'
        },
        {
          id: 30,
          position: '客户端工程师（互动直播）',
          place: '上海',
          time: '全职',
          descri: '',
          responsibility: '',
          required: '本科及以上学历\n熟练掌握 C++/C#，能够学习并使用 QT 等 framework 进行客户端开发\n了解视频编解码大致原理，能够学习并使用 FFmpeg 等 library 进行音视频处理\n重视代码质量及研发规范\n具有较强的沟通能力、分析和解决问题的能力，具备良好的团队合作精神',
          plus: '有 ACM-ICPC 等程序设计竞赛参赛、获奖经历\n使用过 QT 开发过中型以上的项目，有多媒体类软件开发经验\n精通视频编解码以及相关技术\n熟悉 WebRTC 等实时通讯技术',
          materials: '个人简历'
        },
        {
          id: 16,
          position: '商务拓展经理',
          place: '上海',
          time: '全职',
          descri: '这个职务负责与国内外各个媒体和平台、主播洽谈合作，并促成最终合作，推动业务增长。规划和沟通能力对于该职位来说尤为重要，商务拓展经理需要能够自我驱动以及对游戏事业有足够的热情。',
          responsibility: '市场拓展，对外商务合作与关系维护\n流量引入，内容输出，合作渠道维护\n与国内外各个媒体和平台、主播洽谈合作',
          required: '热爱游戏，对游戏行业有足够的理解\n至少二年的游戏行业的商务拓展从业经验，熟悉国内外各个媒体平台和主播\n熟练使用办公软件 和 Photoshop\n极强的沟通能力，能与管理团队、合作伙伴以及其他团队保持良好的沟通\n能够促成跨团队、公司的集体合作\n在高压下解决问题的能力\n中文，英文流利\n本科以上学位',
          plus: '留学经历\n托福 100+',
          materials: '个人简历'
        },
        {
          id: 10,
          position: '品牌运营',
          place: '上海',
          time: '全职',
          descri: '在游戏从内部推向市场的过程中，品牌运营的作用至关重要。品牌运营在游戏发布的各个阶段都需要让市场和玩家了解未来 Recreate games 所创作的产品。不仅是游戏发布上线运营后，品牌运营在上线前预热也同样重要。品牌运营将会和媒体一同将 Recreate games 游戏理念和产品传递给最终用户。',
          responsibility: '在媒体和社交平台为游戏产品建立持久的品牌信息投放渠道\n与策划，美术团队共同创造营销信息资产\n与策划，外部团队共同制定广告投放策略和资产\n与玩家社群达成良好的伙伴关系\n评估信息和广告投放数据表现',
          required: '热爱游戏\n拥有两年以上游戏或互联网品牌运营经验\n拥有完整从早期策划到最终落地的媒体策划和采购经验\n中文和英文流畅\n优秀的沟通和表达能力',
          plus: '拥有两年以上数字广告投放和监测经验\n拥有两年以上社交网络营销经验',
          materials: '个人简历'
        },
        {
          id: 17,
          position: '新媒体运营',
          place: '上海',
          time: '全职',
          descri: '对于一个成功的游戏来说，发行前后的宣传和对玩家群体的维护至关重要。除了游戏本身的品质之外，游戏运营的成果离不开宣传策略的制定，热点的抓取，和对玩家市场的充分了解。新媒体运营需要深入掌握玩家和相关社群的动向，保证游戏的常时热度，以及在热点出现时敏锐的反应速度。',
          responsibility: '负责各社交媒体的内容定期生产和发布\n维护各平台用户关系\n和平台主播、其他游戏厂商建立合作关系\n评估当前各平台数据表现\n制定短期和长期的游戏运营/宣传计划\n根据用户偏好和平台规则，给产品、美术团队提出内容生产建议',
          required: '优秀的英文口语、写作、阅读能力\n优秀的沟通和表达能力\n对各新媒体/社交平台有深入了解\n熟练使用至少一种视频编辑软件和一种修图软件',
          plus: '留学经历\n托福 100+\n数学、统计、产品、运营相关经历\n有社交媒体、短视频平台账号运营经验',
          materials: '个人简历'
        },
    
        
        
      ],
      resizeBinder: null,
      scrollTops: [],
      imgsLoaded: false,
    }
  },
  beforeDestroy(){
    document.removeEventListener('scroll', this.scrollingBinder, false);
    window.removeEventListener('resize', this.resizeBinder, false);
    document.removeEventListener('click', this.hideMobileNav, false);
  },
  mounted() {
    this.resizeBinder = this.foldMobileNav.bind(this);
    this.scrollingBinder = this.pageScrolling.bind(this);
    document.addEventListener('scroll', this.scrollingBinder, false);
    window.addEventListener('resize', this.resizeBinder, false);
    document.addEventListener('click', this.hideMobileNav, false);
    this.foldMobileNav();
  },
  methods: {
    toggleMobileNav(e) {
      e.preventDefault();
      e.stopPropagation();
      this.isShowMobileNav = !this.isShowMobileNav;
    },
    hideMobileNav() {
      if(this.isShowMobileNav)
        this.isShowMobileNav = false;
    },
    foldMobileNav() {
      this.foldNav = window.innerWidth <= 800;
    },
    pageScrolling(){
      let _scrollTop = scrollTop();
      if(_scrollTop > 0 && !this.isNavFixed){
        this.isNavFixed = true;
      }else if(_scrollTop === 0 && this.isNavFixed){
        this.isNavFixed = false;
      }
    },
    updateScrollTops(arr){
      this.scrollTops = arr;
    },
    imgLoaded(){
      // if(!this.imgsLoaded){
      //   this.imgsLoaded = true;
        let itemId = this.navs.findIndex(hash => hash === this.$route.hash);
        if(itemId > 0) {
           this.scrollTo(this.scrollTops[itemId]);
        }
      // }
    },
    scrollTo(y){
      document.documentElement.scrollTop = document.body.scrollTop = y;
    },
    updateNav(itemId, isClicked) {
      this.isClickedTrigger = isClicked || false;
      this.activatedNavItem = itemId;
      if(isClicked && this.$route.hash === this.navs[itemId]) {
        // document.querySelector(this.navs[itemId]).scrollIntoView();
        this.scrollTo(this.scrollTops[itemId]);
      }

    },
    resetClickedTrigger(){
      this.isClickedTrigger = false;
    },
  }
}
</script>

<style scoped>

</style>