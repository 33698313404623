import axios  from 'axios';

export function sendEmail(formData) {
	return axios({
		method: 'post',
		url: 'https://wekb0rx9b3.execute-api.ap-northeast-1.amazonaws.com/Prod/mailer',
		data: formData,
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	});
}